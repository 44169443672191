* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

html{
  touch-action:pan-down
}